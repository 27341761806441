import { Avatar as MantineAvatar } from '@mantine/core';
import { FC } from 'react';
import { AvatarProps } from './types';
import { useStyles } from '@styles/authorsPage/styles';

export const Avatar: FC<AvatarProps> = ({
  image,
  color,
  size,
  className,
  name,
}) => {
  const { theme } = useStyles();

  const getInitials = (name: string) => {
    const nameArray = name.split(' ');
    return nameArray.map((word) => word.charAt(0).toUpperCase()).join('');
  };

  return (
    <>
      {!image && name ? (
        <MantineAvatar
          src={image}
          sx={{
            '.mantine-Avatar-placeholder': {
              backgroundColor: color ?? theme.colors.primary[0],
              color: `${theme.white} !important`,
            },
          }}
          alt={name}
          size={size}
          style={{ borderRadius: '100%', lineHeight: 1, border: 0 }}
          className={className ?? ''}
        >
          {getInitials(name)}
        </MantineAvatar>
      ) : (
        <MantineAvatar
          sx={{
            '.mantine-Avatar-placeholder': {
              backgroundColor: color ?? theme.colors.primary[0],
              color: theme.white,
            },
          }}
          size={size}
          alt={name}
          src={image}
          style={{ borderRadius: '100%' }}
          className={className ?? ''}
        />
      )}
    </>
  );
};
