import { FC } from 'react';

import Link from 'next/link';

export type LogoProps = {
  href: '/' | '/admin/news';
  variant?: 'white' | 'black' | 'orange';
};

export const Logo: FC<LogoProps> = ({ href, variant = 'orange' }) => {
  if (variant === 'white') {
    return (
      <Link href={href} aria-label="logo-link">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="Logo"
          height={40}
          width={40}
          viewBox="0 0 60 65"
        >
          <g id="Layer_1-2" data-name="Layer 1">
            <g>
              <path
                style={{ fill: '#fff' }}
                d="M59.61,23.53l-5.97-.8c-4.7,5.73-8.51,8.99-8.97,8.69-.65-.42,1.18-7.62,7.66-13.02l-4.38-5.57c-5.34,4.21-9.14,10.01-11.8,15.53l-.81-8.77-7.03-.2c-3.49,23.28-9.21,30.29-12.51,29.55,3.93-11.78,6.39-21.01,7.52-28.15,1.27-7.99,.85-13.31-1.32-16.73C18.07-1.96,9.82-1.04,5.64,5.16,1.81,10.39-.19,18.77,.01,28.75c.24,11.49,3.42,24.42,9.22,37.38l6.47-2.9c-1.21-2.71-2.27-5.33-3.19-7.89,8.61,2.8,13.84-3.33,17.76-13.49l.36,3.92,7.02,.29c.01-.08,.66-3.61,2.3-8.3,4.2,3.48,10.05-.52,15.46-6.28l2.39,5.33,6.47-2.9-4.66-10.37ZM9.48,45.47c-4.22-16.9-1.89-28.5,.89-34.51,1.93-4.18,8.5-8.56,5.95,8.73-.95,6.42-3.31,15.07-6.84,25.78Z"
              />
              <rect
                style={{ fill: '#fff' }}
                x="28.91"
                y="9.82"
                width="6.45"
                height="7.09"
                transform="translate(17.67 45.02) rotate(-88.02)"
              />
            </g>
          </g>
        </svg>
      </Link>
    );
  }

  if (variant === 'black') {
    return (
      <Link href={href} aria-label="logo-link">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="Logo"
          height={40}
          width={40}
          viewBox="0 0 60 65"
        >
          <g id="Layer_1-2" data-name="Layer 1">
            <g>
              <path
                style={{ fill: ' #0f0f13' }}
                d="M59.61,23.53l-5.97-.8c-4.7,5.73-8.51,8.99-8.97,8.69-.65-.42,1.18-7.62,7.66-13.02l-4.38-5.57c-5.34,4.21-9.14,10.01-11.8,15.53l-.81-8.77-7.03-.2c-3.49,23.28-9.21,30.29-12.51,29.55,3.93-11.78,6.39-21.01,7.52-28.15,1.27-7.99,.85-13.31-1.32-16.73C18.07-1.96,9.82-1.04,5.64,5.16,1.81,10.39-.19,18.77,.01,28.75c.24,11.49,3.42,24.42,9.22,37.38l6.47-2.9c-1.21-2.71-2.27-5.33-3.19-7.89,8.61,2.8,13.84-3.33,17.76-13.49l.36,3.92,7.02,.29c.01-.08,.66-3.61,2.3-8.3,4.2,3.48,10.05-.52,15.46-6.28l2.39,5.33,6.47-2.9-4.66-10.37ZM9.48,45.47c-4.22-16.9-1.89-28.5,.89-34.51,1.93-4.18,8.5-8.56,5.95,8.73-.95,6.42-3.31,15.07-6.84,25.78Z"
              />
              <rect
                style={{ fill: ' #0f0f13' }}
                x="28.91"
                y="9.82"
                width="6.45"
                height="7.09"
                transform="translate(17.67 45.02) rotate(-88.02)"
              />
            </g>
          </g>
        </svg>
      </Link>
    );
  }

  return (
    <Link href={href} aria-label="logo-link">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Logo"
        height={40}
        width={40}
        viewBox="0 0 60 65"
      >
        <g id="Layer_1-2" data-name="Layer 1">
          <g>
            <path
              style={{ fill: ' #ee3534' }}
              d="M59.61,23.53l-5.97-.8c-4.7,5.73-8.51,8.99-8.97,8.69-.65-.42,1.18-7.62,7.66-13.02l-4.38-5.57c-5.34,4.21-9.14,10.01-11.8,15.53l-.81-8.77-7.03-.2c-3.49,23.28-9.21,30.29-12.51,29.55,3.93-11.78,6.39-21.01,7.52-28.15,1.27-7.99,.85-13.31-1.32-16.73C18.07-1.96,9.82-1.04,5.64,5.16,1.81,10.39-.19,18.77,.01,28.75c.24,11.49,3.42,24.42,9.22,37.38l6.47-2.9c-1.21-2.71-2.27-5.33-3.19-7.89,8.61,2.8,13.84-3.33,17.76-13.49l.36,3.92,7.02,.29c.01-.08,.66-3.61,2.3-8.3,4.2,3.48,10.05-.52,15.46-6.28l2.39,5.33,6.47-2.9-4.66-10.37ZM9.48,45.47c-4.22-16.9-1.89-28.5,.89-34.51,1.93-4.18,8.5-8.56,5.95,8.73-.95,6.42-3.31,15.07-6.84,25.78Z"
            />
            <rect
              style={{ fill: ' #ee3534' }}
              x="28.91"
              y="9.82"
              width="6.45"
              height="7.09"
              transform="translate(17.67 45.02) rotate(-88.02)"
            />
          </g>
        </g>
      </svg>
    </Link>
  );
};
