import {
  NewspaperIcon,
  UserIcon,
  Bars3BottomLeftIcon,
  BanknotesIcon,
  Cog8ToothIcon,
  ChatBubbleLeftIcon,
  MicrophoneIcon,
  PhoneIcon,
  PencilSquareIcon,
  BellIcon,
  InformationCircleIcon,
  UserGroupIcon,
  IdentificationIcon,
  DocumentTextIcon,
  ArchiveBoxIcon,
  TableCellsIcon,
} from '@heroicons/react/24/outline';

export const ROUTES_FRONTEND_WITHOUT_RADIO = [
  {
    name: 'Authors',
    label: 'navbar.authors',
    route: '/autores',
  },
  {
    name: 'Editorial',
    label: 'navbar.editorial',
    route: '/editorial',
  },
  {
    name: 'Technical Sheet',
    label: 'navbar.technicalSheet',
    route: '/ficha-tecnica',
  },
  {
    name: 'Contacts',
    label: 'navbar.contacts',
    route: '/contactos',
  },
  {
    name: 'Podcasts',
    label: 'navbar.podcasts',
    route: '/podcasts',
  },
];

export const ROUTES_FRONTEND_WITH_RADIO = [
  {
    name: 'Authors',
    label: 'navbar.authors',
    route: '/autores',
  },
  {
    name: 'Editorial',
    label: 'navbar.editorial',
    route: '/editorial',
  },
  {
    name: 'Technical Sheet',
    label: 'navbar.technicalSheet',
    route: '/ficha-tecnica',
  },
  {
    name: 'Contacts',
    label: 'navbar.contacts',
    route: '/contactos',
  },
  {
    name: 'Radio',
    label: 'navbar.radio',
    route: '/radio',
  },
  {
    name: 'Podcasts',
    label: 'navbar.podcasts',
    route: '/podcasts',
  },
];

export const SIDEBAR_BACKOFFICE_ROUTES = [
  { href: '/admin/news', label: 'dashboard.news', icon: NewspaperIcon },
  {
    href: '/admin/live-blogs',
    label: 'dashboard.liveBlog',
    icon: ChatBubbleLeftIcon,
  },
  {
    href: '/admin/podcasts',
    label: 'dashboard.podcasts',
    icon: MicrophoneIcon,
  },
  {
    href: '/admin/publicity',
    label: 'dashboard.publicity',
    icon: BanknotesIcon,
  },
  { href: '/admin/authors', label: 'dashboard.authors', icon: UserIcon },
  {
    href: '/admin/categories',
    label: 'dashboard.categories',
    icon: Bars3BottomLeftIcon,
  },
  {
    href: '/admin/notifications',
    label: 'dashboard.notifications',
    icon: BellIcon,
  },
  { href: '/admin/seo', label: 'dashboard.seo', icon: IdentificationIcon },
  {
    href: '/admin/editorial',
    label: 'dashboard.editorial',
    icon: PencilSquareIcon,
  },
  {
    href: '/admin/technical-sheet',
    label: 'dashboard.technicalSheet',
    icon: TableCellsIcon,
  },
  {
    href: '/admin/contacts',
    label: 'dashboard.contacts',
    icon: PhoneIcon,
  },
  {
    href: '/admin/terms',
    label: 'dashboard.terms',
    icon: ArchiveBoxIcon,
  },
  {
    href: '/admin/privacy',
    label: 'dashboard.privacy',
    icon: DocumentTextIcon,
  },
  {
    href: '/admin/faqs',
    label: 'dashboard.faqs',
    icon: InformationCircleIcon,
  },
  { href: '/admin/users', label: 'dashboard.users', icon: UserGroupIcon },
  { href: '/admin/settings', label: 'dashboard.settings', icon: Cog8ToothIcon },
];

export const AUTHOR = 'AUTHOR';
export const ADMIN = 'ADMIN';
export const USER = 'USER';

export const ADMIN_ROUTES = [
  '/admin/news',
  '/admin/users',
  '/admin/authors',
  '/admin/seo',
  '/admin/categories',
  '/admin/podcasts',
  '/admin/publicity',
  '/admin/live-blogs',
  '/admin/live-blogs/:slug',
  '/admin/podcasts/:slug',
  '/admin/settings',
  '/admin/technical-sheet',
  '/admin/editorial',
  '/admin/terms',
  '/admin/privacy',
  '/admin/contacts',
  '/admin/notifications',
  '/admin/faqs',
];

export const USER_ROUTES = [
  '/admin/news',
  '/admin/categories',
  '/admin/authors',
  '/admin/authors',
  '/admin/podcasts',
  '/admin/publicity',
  '/admin/live-blogs',
  '/admin/live-blogs/:slug',
  '/admin/podcasts/:slug',
  '/admin/settings',
  '/admin/notifications',
  '/admin/faqs',
];

export const AUTHOR_ROUTES = [
  '/admin/news',
  '/admin/settings',
  '/admin/faqs',
  '/admin/categories',
  '/admin/authors',
];

export const ROUTES = {
  [ADMIN]: ADMIN_ROUTES,
  [USER]: USER_ROUTES,
  [AUTHOR]: AUTHOR_ROUTES,
};
