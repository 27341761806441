import { createStyles, rem } from '@mantine/core';

export const useFooterStyles = createStyles((theme) => ({
  footer: {
    marginBottom: 40,
    marginTop: 40,
    paddingTop: `calc(${theme.spacing.xl} * 2)`,
    paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    backgroundColor: theme.colorScheme === 'dark' ? 'transparent' : 'white',
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },

  logo: {
    maxWidth: 200,
    [theme.fn.smallerThan('sm')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },

  description: {
    color: theme.colors.gray[6],
    marginTop: 5,
    [theme.fn.smallerThan('sm')]: {
      marginTop: theme.spacing.xs,
    },
  },

  link: {
    display: 'block',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },

  linkWiseverge: {
    color: theme.colors.gray[6],
    display: 'block',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },

  imageLink: {
    marginTop: 12,
    '&:hover': {
      cursor: 'pointer',
    },
  },

  socialIcon: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[5],
    width: 22,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));
