import { useEffect } from 'react';

import Router, { NextRouter } from 'next/router';

// This code is used only to fix refresh Page Bug, because the scrollRestoration fix of next turns off the page refresh going to previous content

const saveScrollPos = (url: string) => {
  const scrollPos = { x: window.scrollX, y: window.scrollY };

  sessionStorage.setItem(url, JSON.stringify(scrollPos));
};

const restoreScrollPos = (url: string) => {
  const scrollPos = JSON.parse(sessionStorage.getItem(url) as string);
  sessionStorage.removeItem(url);
  if (scrollPos) {
    setTimeout(() => {
      window.scrollTo(scrollPos.x, scrollPos.y);
    });
  }
};

// BeforePopState triggered by back and forward buttons
// BeforeUnload triggered when a user wants to leave a page or refresh

export const useScrollRestoration = (router: NextRouter) => {
  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      let shouldScrollRestore = false;
      restoreScrollPos(router.asPath);

      const onBeforeUnload = () => {
        saveScrollPos(router.asPath);

        return undefined;
      };

      const onRouteChangeComplete = (url: string) => {
        if (shouldScrollRestore) {
          shouldScrollRestore = false;
          restoreScrollPos(url);
        }
      };

      window.addEventListener('beforeunload', onBeforeUnload);
      Router.events.on('routeChangeComplete', onRouteChangeComplete);

      Router.beforePopState(() => {
        shouldScrollRestore = true;
        return true;
      });

      return () => {
        window.removeEventListener('beforeunload', onBeforeUnload);
        Router.events.off('routeChangeComplete', onRouteChangeComplete);
        Router.beforePopState(() => true);
      };
    }
  }, [router]);
};
