import { MantineTheme, rem } from '@mantine/core';
import { FONT_PROJECT, FONT_PROJECT_SECONDARY } from 'public/fonts';

export const themeProps = {
  breakpoints: {
    xxs: '20em',
  },
  fontFamilyMonospace: 'Monaco, monospace',
  fontFamily: FONT_PROJECT.style.fontFamily,
  lineHeight: 1.4,
  components: {
    ActionIcon: {
      variants: {
        roundedGray: (theme: MantineTheme) => ({
          root: {
            backgroundColor:
              theme.colorScheme === 'dark'
                ? theme.colors.gray[8]
                : theme.colors.gray[2],
            color:
              theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[9],
            border: 0,
            '&:not([data-disabled])': theme.fn.hover({
              backgroundColor: theme.fn.darken(
                theme.colorScheme === 'dark'
                  ? theme.colors.gray[7]
                  : theme.colors.gray[3],
                0.05
              ),
            }),
            padding: 8,
            borderRadius: 300,
          },
        }),
      },
    },
    Button: {
      variants: {
        danger: (theme: MantineTheme) => ({
          root: {
            backgroundColor: theme.colors.red[6],
            color: 'white',
            border: 0,
            '&:not([data-disabled])': theme.fn.hover({
              backgroundColor: theme.fn.darken(theme.colors.red[7], 0.05),
            }),
          },
          leftIcon: {
            marginRight: theme.spacing.md,
          },
        }),
        primary: (theme: MantineTheme) => ({
          root: {
            backgroundColor: theme.colors.primary[0],
            color: 'white',
            border: 0,
            '&:not([data-disabled])': theme.fn.hover({
              backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.05),
            }),
          },
          rightIcon: {
            width: 16,
            marginLeft: theme.spacing.sm,
          },
          leftIcon: {
            width: 16,
            marginRight: theme.spacing.sm,
          },
        }),
        secondary: (theme: MantineTheme) => ({
          root: {
            border: 0,
            color: theme.colors.dark[2],
            '&:not([data-disabled])': theme.fn.hover({
              backgroundColor: theme.fn.lighten(theme.colors.dark[1], 0.8),
            }),
          },
          rightIcon: {
            marginLeft: theme.spacing.sm,
          },
          leftIcon: {
            width: 16,
            marginRight: theme.spacing.sm,
          },
        }),
        tertiary: (theme: MantineTheme) => ({
          root: {
            backgroundColor: theme.colors.primary[0],
            border: 0,
            color: theme.white,
            '&:not([data-disabled])': theme.fn.hover({
              backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.05),
            }),
            width: 200,
            paddingTop: 4,
            paddingBottom: 4,
            borderRadius: 0,
          },
          rightIcon: {
            marginLeft: theme.spacing.sm,
          },
          leftIcon: {
            width: 16,
            marginRight: theme.spacing.sm,
          },
        }),
        subtle: (theme: MantineTheme) => ({
          root: {
            border: 0,
            color: theme.colors.primary[0],
            fontWeight: 400,
            fontSize: rem(16),
            '&:not([data-disabled])': theme.fn.hover({
              backgroundColor: 'transparent',
              color: theme.colors.primary[0],
              fontWeight: 500,
            }),
          },
          rightIcon: {
            marginLeft: theme.spacing.md,
            width: 13,
          },
        }),
        subtleDanger: (theme: MantineTheme) => ({
          root: {
            color: theme.colors.red[5],
            border: 0,
            '&:not([data-disabled])': theme.fn.hover({
              backgroundColor: theme.fn.lighten(theme.colors.red[1], 0.05),
            }),
          },
          leftIcon: {
            marginRight: theme.spacing.md,
          },
          rightIcon: {
            marginRight: theme.spacing.md,
            width: 16,
          },
        }),
        subtleLessPadding: (theme: MantineTheme) => ({
          root: {
            border: 0,
            color:
              theme.colorScheme === 'dark'
                ? theme.colors.gray[2]
                : theme.colors.gray[7],
            fontWeight: 400,
            fontSize: rem(16),
            '&:not([data-disabled])': theme.fn.hover({
              backgroundColor:
                theme.colorScheme === 'dark'
                  ? theme.colors.gray[8]
                  : theme.colors.gray[2],
              color: theme.fn.darken(
                theme.colorScheme === 'dark'
                  ? theme.colors.gray[3]
                  : theme.colors.gray[8],
                0.2
              ),
              fontWeight: 500,
            }),
            padding: 8,
          },
        }),
        subtleGray: (theme: MantineTheme) => ({
          root: {
            border: 0,
            color:
              theme.colorScheme === 'dark'
                ? theme.colors.tertiary[0]
                : theme.colors.dark[5],
            fontSize: 19,
            fontWeight: 400,
            lineHeight: 1.4,
            '&:not([data-disabled])': theme.fn.hover({
              backgroundColor: 'transparent',
              color: theme.colors.primary[0],
            }),
          },
          rightIcon: {
            marginLeft: 2,
            width: 13,
          },
        }),
      },
    },
    Text: {
      variants: {
        nav: (theme: MantineTheme) => ({
          root: {
            color:
              theme.colorScheme === 'dark'
                ? theme.colors.tertiary[0]
                : theme.colors.dark[5],
            fontSize: 19,
            fontWeight: 400,
            lineHeight: 1.4,
            marginBlockStart: 0,
            marginBlockEnd: 0,
          },
        }),
        body: (theme: MantineTheme) => ({
          root: {
            fontFamily: FONT_PROJECT_SECONDARY.style.fontFamily,
            color:
              theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[9],
            fontSize: 22,
            fontWeight: 400,
            lineHeight: 1.4,
            letterSpacing: 0.5,
            marginBlockStart: 0,
            marginBlockEnd: 0,
          },
        }),
        bodyBold: (theme: MantineTheme) => ({
          root: {
            fontFamily: FONT_PROJECT_SECONDARY.style.fontFamily,
            color:
              theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[9],
            fontSize: 22,
            fontWeight: 600,
            lineHeight: 1.4,
            marginBlockStart: 0,
            marginBlockEnd: 0,
          },
        }),
        bodyOrange: (theme: MantineTheme) => ({
          root: {
            fontFamily: FONT_PROJECT_SECONDARY.style.fontFamily,
            color: theme.colors.primary[0],
            fontSize: 22,
            fontWeight: 400,
            lineHeight: 1.4,
            marginBlockStart: 0,
            marginBlockEnd: 0,
          },
        }),
        bodyLink: (theme: MantineTheme) => ({
          root: {
            fontFamily: FONT_PROJECT_SECONDARY.style.fontFamily,
            color: theme.colors.primary[0],
            fontSize: 22,
            fontWeight: 400,
            lineHeight: 1.4,
            marginBlockStart: 0,
            marginBlockEnd: 0,
            fontStyle: 'italic',
            textDecoration: 'underline',
          },
        }),
        body2: (theme: MantineTheme) => ({
          root: {
            fontFamily: FONT_PROJECT_SECONDARY.style.fontFamily,
            color:
              theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[9],
            fontSize: 23,
            fontWeight: 400,
            lineHeight: 1.4,
            marginBlockStart: 0,
            marginBlockEnd: 0,
          },
        }),
        body3: (theme: MantineTheme) => ({
          root: {
            fontFamily: FONT_PROJECT_SECONDARY.style.fontFamily,
            color:
              theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[9],
            fontSize: 23,
            fontWeight: 400,
            lineHeight: 1.4,
            marginBlockStart: 0,
            marginBlockEnd: 0,
          },
        }),
        body3Link: (theme: MantineTheme) => ({
          root: {
            fontFamily: FONT_PROJECT_SECONDARY.style.fontFamily,
            color: theme.colors.primary[0],
            fontSize: 23,
            fontWeight: 400,
            lineHeight: 1.4,
            marginBlockStart: 0,
            marginBlockEnd: 0,
            fontStyle: 'italic',
            textDecoration: 'underline',
          },
        }),
        body3White: (theme: MantineTheme) => ({
          root: {
            fontFamily: FONT_PROJECT_SECONDARY.style.fontFamily,
            color: theme.white,
            fontSize: 23,
            fontWeight: 500,
            lineHeight: 1.4,
          },
        }),
        caption: (theme: MantineTheme) => ({
          root: {
            fontFamily: FONT_PROJECT_SECONDARY.style.fontFamily,
            color:
              theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[7],
            fontSize: 17,
            fontWeight: 400,
            lineHeight: 1.4,
          },
        }),
        caption1: (theme: MantineTheme) => ({
          root: {
            fontFamily: FONT_PROJECT_SECONDARY.style.fontFamily,
            color:
              theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[7],
            fontSize: 17,
            fontWeight: 400,
            lineHeight: 1.4,
            '&:hover': {
              color: theme.fn.darken(theme.colors.primary[0], 0.05),
            },
          },
        }),
        caption1Bold: (theme: MantineTheme) => ({
          root: {
            fontFamily: FONT_PROJECT_SECONDARY.style.fontFamily,
            color:
              theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[9],
            fontSize: 17,
            fontWeight: 600,
            lineHeight: 1.4,
          },
        }),
        caption1BoldWhite: (theme: MantineTheme) => ({
          root: {
            fontFamily: FONT_PROJECT_SECONDARY.style.fontFamily,
            color: theme.colorScheme === 'dark' ? theme.white : theme.white,
            fontSize: 17,
            fontWeight: 700,
            lineHeight: 1.4,
          },
        }),
        caption1Orange: (theme: MantineTheme) => ({
          root: {
            fontFamily: FONT_PROJECT_SECONDARY.style.fontFamily,
            color: theme.colors.primary[0],
            fontSize: 17,
            fontWeight: 600,
            lineHeight: 1.71,
          },
        }),
        caption1Red: (theme: MantineTheme) => ({
          root: {
            color: theme.colors.red[6],
            fontSize: 'calc(0.875rem - 0.125rem)',
            fontWeight: 400,
            lineHeight: 1.4,
          },
        }),
        caption1Gray: (theme: MantineTheme) => ({
          root: {
            fontFamily: FONT_PROJECT_SECONDARY.style.fontFamily,
            color: theme.colors.gray[5],
            fontSize: 17,
            fontWeight: 600,
            lineHeight: 1.4,
          },
        }),
        caption2: (theme: MantineTheme) => ({
          root: {
            color:
              theme.colorScheme === 'dark'
                ? theme.colors.gray[5]
                : theme.colors.dark[3],
            fontSize: 15,
            fontWeight: 400,
            lineHeight: 1.4,
          },
        }),
        caption2White: (theme: MantineTheme) => ({
          root: {
            color:
              theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[3],
            fontSize: 17,
            fontWeight: 400,
            lineHeight: 1.4,
            marginBlockStart: 0,
            marginBlockEnd: 0,
          },
        }),
        caption2Orange: (theme: MantineTheme) => ({
          root: {
            fontFamily: FONT_PROJECT_SECONDARY.style.fontFamily,
            color: theme.colors.primary[0],
            fontSize: 15,
            fontWeight: 400,
            lineHeight: 1.4,
          },
        }),
        caption2Gray: (theme: MantineTheme) => ({
          root: {
            fontFamily: FONT_PROJECT_SECONDARY.style.fontFamily,
            color: theme.colors.dark[6],
            fontSize: 12,
            fontWeight: 500,
            lineHeight: 1.4,
          },
        }),
        captionTitle: (theme: MantineTheme) => ({
          root: {
            color:
              theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[7],
            fontSize: 16,
            fontWeight: 600,
            lineHeight: 1.4,
          },
        }),
        captionXsBold: (theme: MantineTheme) => ({
          root: {
            color:
              theme.colorScheme === 'dark'
                ? theme.colors.gray[5]
                : theme.colors.dark[3],
            fontSize: 14,
            fontWeight: 600,
            lineHeight: 1.4,
          },
        }),
        categoryText: (theme: MantineTheme) => ({
          root: {
            color:
              theme.colorScheme === 'dark'
                ? theme.colors.gray[5]
                : theme.colors.dark[3],
            fontSize: 12,
            fontWeight: 400,
            lineHeight: 0.8,
          },
        }),
        error: (theme: MantineTheme) => ({
          root: {
            color: theme.colors.red[7],
            fontSize: 15,
            fontWeight: 500,
            lineHeight: 1.55,
          },
        }),
        subtitle1: (theme: MantineTheme) => ({
          root: {
            color:
              theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[7],
            fontSize: 20,
            fontWeight: 600,
            lineHeight: 1.2,
            marginBlockStart: 0,
            marginBlockEnd: 0,
          },
        }),
        subtitle1Orange: (theme: MantineTheme) => ({
          root: {
            color: theme.colors.primary[0],
            fontSize: 22,
            fontWeight: 600,
            lineHeight: 1.2,
            marginBlockStart: 0,
            marginBlockEnd: 0,
          },
        }),
        subtitle2: (theme: MantineTheme) => ({
          root: {
            color:
              theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[9],
            fontSize: 18,
            fontWeight: 700,
            lineHeight: 1.4,
            marginBlockStart: 0,
            marginBlockEnd: 0,
          },
        }),
        footerText: (theme: MantineTheme) => ({
          root: {
            color:
              theme.colorScheme === 'dark' ? theme.white : theme.colors.gray[6],
            fontSize: theme.fontSizes.xs,
            fontWeight: 500,
            lineHeight: 1.4,
            paddingTop: 3,
            paddingBottom: 3,
          },
        }),
        footerTitle: (theme: MantineTheme) => ({
          root: {
            color:
              theme.colorScheme === 'dark' ? theme.white : theme.colors.gray[8],
            fontSize: theme.fontSizes.xs,
            fontWeight: 600,
            lineHeight: 1.4,
          },
        }),
        logoText: (theme: MantineTheme) => ({
          root: {
            color:
              theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[9],
            fontSize: 20,
            fontWeight: 700,
            lineHeight: 1.18,
          },
        }),
        logoTextWhite: (theme: MantineTheme) => ({
          root: {
            color: theme.colorScheme === 'dark' ? theme.white : theme.white,
            fontSize: 20,
            fontWeight: 700,
            lineHeight: 1.18,
          },
        }),
      },
    },
    Title: {
      variants: {
        h1: (theme: MantineTheme) => ({
          root: {
            color:
              theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[9],
            fontSize: 30,
            fontWeight: 900,
            lineHeight: 1.1,
          },
        }),
        h1White: (theme: MantineTheme) => ({
          root: {
            color: theme.white,
            fontSize: 30,
            fontWeight: 900,
            lineHeight: 1.1,
          },
        }),
        h1Orange: (theme: MantineTheme) => ({
          root: {
            color: theme.colors.primary[0],
            fontSize: 30,
            fontWeight: 900,
            lineHeight: 1.1,
          },
        }),
        h2: (theme: MantineTheme) => ({
          root: {
            color:
              theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[9],
            fontSize: 26,
            fontWeight: 900,
            lineHeight: 1.13,
          },
        }),
        h2Orange: (theme: MantineTheme) => ({
          root: {
            color: theme.colors.primary[0],
            fontSize: 24,
            fontWeight: 900,
            lineHeight: 1.13,
          },
        }),
        h2White: (theme: MantineTheme) => ({
          root: {
            color: theme.white,
            fontSize: 24,
            fontWeight: 700,
            lineHeight: 1.13,
          },
        }),
        h3Orange: (theme: MantineTheme) => ({
          root: {
            color: theme.colors.primary[0],
            fontSize: 22,
            fontWeight: 700,
            lineHeight: 1.18,
            '&:hover': {
              color: theme.fn.darken(theme.colors.primary[0], 0.05),
            },
          },
        }),
        h3Purple: (theme: MantineTheme) => ({
          root: {
            color: theme.colors.secondary[0],
            fontSize: 20,
            fontWeight: 700,
            lineHeight: 1.18,
          },
        }),
        h3Dark: (theme: MantineTheme) => ({
          root: {
            color:
              theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[9],
            fontSize: 20,
            fontWeight: 700,
            lineHeight: 1.18,
          },
        }),
        h3White: (theme: MantineTheme) => ({
          root: {
            color: theme.colorScheme === 'dark' ? theme.white : theme.white,
            fontSize: 20,
            fontWeight: 700,
            lineHeight: 1.18,
          },
        }),
        h4: (theme: MantineTheme) => ({
          root: {
            color:
              theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[9],
            fontSize: 18,
            fontWeight: 600,
            lineHeight: 1.18,
          },
        }),
        h4Dark: (theme: MantineTheme) => ({
          root: {
            color:
              theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[9],
            fontSize: 20,
            fontWeight: 700,
            lineHeight: 1.18,
            '&:hover': {
              color: theme.fn.darken(theme.colors.primary[0], 0.05),
            },
          },
        }),
        h5Dark: (theme: MantineTheme) => ({
          root: {
            color:
              theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[9],
            fontSize: 15,
            fontWeight: 400,
            lineHeight: 1.4,
            '&:hover': {
              color: theme.fn.darken(theme.colors.primary[0], 0.05),
            },
          },
        }),
      },
    },
  },
  other: {
    title: 30,
    tableIconSize: 15,
    trashIconColor: '#F03E3E',
  },
};

export type TextVariants =
  | 'body'
  | 'bodyBold'
  | 'bodyOrange'
  | 'bodyLink'
  | 'caption1'
  | 'caption1Orange'
  | 'caption2Orange'
  | 'caption1BoldWhite'
  | 'caption2'
  | 'caption2White'
  | 'subtitle1'
  | 'subtitle1Orange'
  | 'subtitle2';

export type TitleVariants =
  | 'h1'
  | 'h1Orange'
  | 'h2'
  | 'h2Orange'
  | 'h3'
  | 'h3Purple'
  | 'h3Dark'
  | 'h3White'
  | 'h4Dark'
  | 'h5Dark';

export type ButtonVariants = 'primary' | 'secondary' | 'subtle' | 'danger';
